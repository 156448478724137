import i18n from "@/i18n";
import { markRaw, toRaw } from "vue";
import {
  Plus,
  BottomLeft,
  TopRight,
  Search,
  Download,
} from "@element-plus/icons-vue";
import api from "@/api";
import moment from "moment";
import { getUploadUrl } from "../../utils/tools";
const { t } = i18n.global;
export default {
  tabsLabel: {
    tabA: t("report.gzbj"),
    tabB: t("map.xiangqingtongji"),
  },
  tableCols: [
    { name: t("report.cph"), filed: "cph" },
    { name: t("report.sbh"), filed: "sbh" },
    { name: t("report.ssgs"), filed: "ssgs" },
    {
      name: t("report.cbyc"),
      filed: "cpyc",
      render: (row) => {
        if (row.cpyc > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.cpyc + "</span>"
          );
        } else {
          return row.cpyc;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.cpyc) > 0)
          callback({ type: "detail_type", row: row, kind: 1 });
      },
    },
    {
      name: t("report.gpscw"),
      filed: "gpscw",
      render: (row) => {
        if (row.gpscw > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.gpscw + "</span>"
          );
        } else {
          return row.gpscw;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.gpscw) > 0)
          callback({ type: "detail_type", row: row, kind: 2 });
      },
    },
    {
      name: t("report.dyyc"),
      filed: "dyyc",
      render: (row) => {
        if (row.dyyc > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.dyyc + "</span>"
          );
        } else {
          return row.dyyc;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.dyyc) > 0)
          callback({ type: "detail_type", row: row, kind: 3 });
      },
    },
    {
      name: t("report.spds_c"),
      filed: "spds",
      render: (row) => {
        if (row.spds > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.spds + "</span>"
          );
        } else {
          return row.spds;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.spds) > 0)
          callback({ type: "detail_type", row: row, kind: 4 });
      },
    },
  ],
  tableOps: [
    {
      opType: "btn",
      icon: markRaw(Search),
      name: t("commKey.DETAIL"),
      type: "success",
      click: (row, callback) => {
        callback({ type: "detail", row: row });
      },
    },
  ],
  searchCols: [
    // {
    //   type: "tsel",
    //   ref: "refTree",
    //   key: "devids",
    //   val: [],
    //   title: t("commKey.keyname"),
    //   props: {
    //     icon: "icon",
    //     value: "gps_id",
    //     label: "nodeName",
    //     children: "children",
    //   },
    // },
    {
      type: "car",
      key: "devids",
    },
    {
      type: "sel",
      key: "dateType",
      val: 1,
      title: "",
      data: [
        { name: t("map.jintian"), value: 1 },
        { name: t("map.zuotian"), value: 2 },
        { name: t("map.benzhou"), value: 3 },
        { name: t("map.shangzhou"), value: 4 },
        { name: t("map.benyue"), value: 5 },
        { name: t("map.shangyue"), value: 6 },
        { name: t("map.zidingyi"), value: 7 },
      ],
      change: (val, callback) => {
        callback({ type: "change_time", evt: val });
        // console.log(obj);
      },
    },
    {
      type: "datetime",
      key: "begintime",
      val: moment().format("YYYY-MM-DD 00:00:00"),
      title: t("commKey.STARTDATE"),
    },
    {
      type: "datetime",
      key: "endtime",
      val: moment().format("YYYY-MM-DD 23:59:59"),
      title: t("commKey.ENDDATE"),
    },
    // {
    //   type: "dtr",
    //   key: "times",
    //   val: [
    //     moment().format("YYYY-MM-DD 00:00:00"),
    //     moment().format("YYYY-MM-DD 23:59:59"),
    //   ],
    //   title_s: t("commKey.STARTDATE"),
    //   title_e: t("commKey.ENDDATE"),
    // },

    {
      type: "btn",
      btnType: "success",
      icon: markRaw(Search),
      title: t("commKey.Search"),
      click: (callback) => {
        callback({ type: "search" });
      },
    },

    {
      type: "btn",
      btnType: "",
      icon: markRaw(Download),
      title: t("commKey.EXCEL"),
      click: (callback) => {
        callback({ type: "download" });
      },
    },
    {
      type: "btn",
      btnType: "warning",
      icon: markRaw(Download),
      title: t("commKey.PDF"),
      click: (callback) => {
        callback({ type: "export" });
      },
    },
  ],
  tableDetailCols: [
    { name: t("report.cph"), filed: "cph" },
    { name: t("report.sbh"), filed: "sbh" },
    { name: t("report.ssgs"), filed: "ssgs" },
    { name: t("report.bjsj"), filed: "gpssj" },
    { name: t("report.ejsj"), filed: "aAlarmEndTime" },

    {
      name: t("report.bjnr"),
      filed: "bgzt",
      render: (row) => {
        return t("map.e_" + row.bgzt);
      },
    },
    {
      name: t("report.xq"),
      filed: "xq",
      render: (row) => {
        let rt;
        if (row["bgzt"] == "103") {
          var str_str = row.xq;
          var str_ay = str_str.split(",");
          var result_ay = [];
          for (var i = 0; i < str_ay.length; i++) {
            if (str_ay[i] != "") {
              if (str_ay[i] == 1) {
                result_ay.push("HDD");
              } else {
                if (str_ay[i] == 2) {
                  result_ay.push("SD");
                } else {
                  result_ay.push("SD" + (parseInt(str_ay[i]) - 1));
                }
              }
            }
          }
          rt = result_ay.join(",");
        } else if (row["bgzt"] == "101") {
          //磁盘异常 视频丢失
          var str_str = row.xq;
          var str_ay = str_str.split(",");
          var result_ay = [];
          for (var i = 0; i < str_ay.length; i++) {
            if (str_ay[i] != "") {
              result_ay.push("CH" + str_ay[i]);
            }
          }
          rt = result_ay.join(",");
        }
        return rt;
      },
    },
    { name: t("report.su"), filed: "sudu" },
    {
      name: t("report.gps_dw"),
      filed: "dw",
      render: (row) => {
        return t("map.gps_" + row.islatlng);
      },
    },
    {
      name: t("report.dswz"),
      filed: "bjwz",
      render: (row) => {
        if (row.addr == "") {
          return (
            "<img src=" +
            require("../../assets/map.png") +
            " style='width:25px'>"
          );
        } else {
          return row.addr;
        }
      },
      click: (row, callback) => {
        if (row.pLongitude && row.pLatitude)
          callback({
            type: "get_addr",
            row: row,
            fd: "addr",
            lng: row.pLongitude,
            lat: row.pLatitude,
          });
        // console.log(row);
      },
      // render: (row) => {
      //   return row.address_info;
      // },
    },
  ],
};
